.progress-bar {
    background-color: theme-color(secondary);
}

.progress-overlay {
    background-color: rgba(255,255,255,0.6);
    border-radius:4px;
    width: 100%;
    height: 100%;
    margin-top: 53px;
    position: absolute;
    z-index:100
}