/* Tags */

div.tagsinput {
  padding: 10px 10px 0px;
  border-color: $border-color;
  
  span.tag {
    background: theme-color(primary);
    border: 0;
    color: #fff;
    padding: 6px 14px;
    font-size: .8125rem;
    font-family: inherit;
    line-height: 1;
    a {
      color: #fff;
    }
  }
}

.rtl {
  div.tagsinput {
    span.tag,
    div {
      float: right;
    }
  }
}
