// Write your custom css/scss here

.dropdown .dropdown-menu, 
.btn-group .dropdown-menu, 
.fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .fc-button-group .dropdown-menu {
    padding:0px;
    border-radius:2px;
}
.dropdown .dropdown-menu .dropdown-item.active, .btn-group .dropdown-menu .dropdown-item.active, 
.fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .fc-button-group .dropdown-menu .dropdown-item.active {
    background-color: theme-color(primary-muted);
    color: theme-color(primary);
    border-radius:0px;
}

.dropdown .dropdown-menu .dropdown-item, .btn-group .dropdown-menu .dropdown-item, 
.fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .fc-button-group .dropdown-menu .dropdown-item {
    font-size: 0.812rem;
    padding: 0.7rem 1.875rem;
    transition: all 0.2s ease-in-out;
    border-radius: 2px;
    border-bottom: 1px solid #e8ebf1;
}
.dropdown .dropdown-menu .dropdown-item, .btn-group .dropdown-menu .dropdown-item:last {
    border-bottom: none;
}

.jq-toast-single {
    display: block;
    width: 100%;
    padding: 20px 25px;
    margin: 0 0 5px;
    border-radius: 4px;
    font-size: 14px;
    font-family: "Quicksand", sans-serif;
    font-weight:900;
    line-height: 20px;
    position: relative;
    pointer-events: all!important;
    background-color: #fff;
    color: #000;
    box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.6);
    -webkit-box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.6);
}
.jq-toast-loader {
    background-color:#fccd26 !important;
    display: block;
}

.jq-toast-single.jq-has-icon {
    padding: 20px 15px 20px 35px; 
}

.jq-has-icon .jq-toast-loader::after  {
    color: #000;
    font-size: 1em;
    font-family: 'feather' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    height: 4.5em;
    display: flex;
    align-items: center;
    padding: 1.1em;
}
.jq-has-icon.jq-icon-success .jq-toast-loader::after {
    content: "\E83F";
}


table.dataTable {
    margin-top: 1.5em !important;
}


div.dt-button-background {
    background-color: rgba(0,0,0,0.3);
}

/* Search Panes */
.dtsp-panes {
    display:none;
}

.dt-buttons {
    float:left;
}


.dtsp-panes.dtsp-panesContainer.dropdown-menu {
    border-radius: 4px;
    padding: 1.5em;

}

div.dtsp-panesContainer div.dtsp-searchPane div.dtsp-topRow button {
    border-color: #000;  
}
div.dtsp-panesContainer div.dtsp-searchPane div.dataTables_wrapper {
    border: 1px #e8ebf1 solid;
}

div.dtsp-panesContainer div.dataTables_wrapper div.dataTables_scrollBody div.dtsp-nameCont span.dtsp-name {
    line-height: 2em;
}
.dtsp-paneInputButton{
    height: 3.1em;
    border-color: #000;
}

div.dtsp-panesContainer div.dtsp-searchPane div.dtsp-topRow {
    border-color: #000;  
}

div.dtsp-panesContainer div.dtsp-title {
    float: left;
    padding: 10px 0;
    font-size: 1.4em;
    font-weight: 600;
}

button.btn.dtsp-nameButton {
    padding:0em;
    &:before {
        content: "\e8b3";
        font-family: 'feather';
        font-weight: normal;
    }
}

button.btn.dtsp-countButton {
    padding:0em;
    &:before {
        content: "\e8b3";
        font-family: 'feather';
        font-weight: normal;
    }
}

button.dtsp-searchIcon {
    background-image: none;
    padding:0em;
    &:before {
        content: "\e8b3";
        font-family: 'feather';
        font-weight: normal;
    }
}

.table.table-borderless tr {

}
div.dtsp-topRow div.dtsp-subRow1 button.dtsp-searchIcon {
    background-image: none;

}


.mdi:before, .mdi-set {
    font-size: 1.3em;
}