@media print {
    body {
        zoom: 80%;
    }
    nav {
        display: none;
    }
    .navbar {
        display: none;
    }

    table {
        max-width: 29cm;
    }
    .table {
        width: 100%;
        max-width:26cm;
    }
    .table td, .table th {
        white-space: normal;
        font-size: 1em;
        p {
            margin-bottom: 0.2em;
        }
    }
    .page-a3 {
        size: A3;
        margin: 0;
    }
    .page-break {
        page-break-after: always;
    }

    .main-wrapper .page-wrapper .page-content {
        margin-top: 0px;
        padding: 0px;
    }
    .stretch-card {
        page-break-after: always;
    }
    .no-print {
        display: none;
    }

    .col-md-12  .card {
        min-height: 21cm;
        width: 29.5cm;
        padding: 0cm;
        margin: 0 auto;
        border: none;
        border-radius: 5px;
        background: transparent;
        box-shadow: 0 0 5px rgba(0,0,0,.1);
        text-wrap: wrap;

        .card-header {
            padding: 0.875rem 0;
            margin-bottom: 2em;
        }
        .card-body{
            padding: 0px;
        }

        .card-footer {
            display: none;
        }
    }
    .col-md-6  .card {
        max-height: 21.5cm;
        min-width: 14.5cm;
        padding: 1cm;
        margin: 0cm auto;
        border: 1px #D3D3D3 solid;
        border-radius: 5px;
        background: white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        .card-header {
            padding:0.5em 0em .875rem 0;
            margin-bottom: 2em;
        }
        .card-body{
            padding: 0px;
        }

        .card-footer {
            display: none;
        }
    }
    @page {size: landscape;}
}